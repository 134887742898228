






































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const storagePrefix = 'cz.tobacco.h5.'
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 0,
            pageSize: 10,
            total: 0,
            list: [],
        }
    },
    mounted() {
        this.onInit()
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
        managerInfo(): any {
            return this.$store.state.managerInfo
        },
        isExpert(): boolean {
            return this.current.role == 1
        }
    },
    methods: {
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        // 获取我的提问列表
        getData() {
            const { pageNum, pageSize } = this
            // // 若是专家，应传专家ID参数
            // let expertId = ''
            // if (this.current?.role == 1 ) expertId = this.managerInfo?.id
            // const params = { pageNum, pageSize, expertId }
            const params = { pageNum, pageSize, }
            request.get('/api/chat/rest_live_chat/page', params).then(({list, total})=> {
                // console.log(list)
                if (list && list.length > 0) {
                    list.map((e, index) => {
                        if (!e.creatorInfo || e.creatorInfo === null) {
                            return
                        } else {
                            if (e.creatorInfo.id === this.current.id) {
                                e.chatInfo = e.targetInfo
                            } else {
                                if (e.creatorInfo.id === this.managerInfo?.id) {
                                    e.chatInfo = e.targetInfo
                                } else {
                                    e.chatInfo = e.creatorInfo
                                }
                            }
                        }
                    })
                }
                if(pageNum==1) this.list = list
                else this.list = this.list.concat(list)
                this.total = total
            })
        },
        gotoim(item) {
            // const headimgurl = item.headimgurl? item.headimgurl : item.imgUrl
            // // console.log(headimgurl)
            // const creatorInfo = item.creatorInfo ? item.creatorInfo : ''
            // const targetInfo = item.targetInfo ? item.targetInfo : ''
            // if (creatorInfo.id === this.current.id) {
            //     sessionStorage.setItem(`${storagePrefix}creatorInfo`, JSON.stringify(creatorInfo))
            //     sessionStorage.setItem(`${storagePrefix}targetInfo`, JSON.stringify(targetInfo))
            // } else {
            //     if (creatorInfo.id === this.managerInfo?.id) {
            //         sessionStorage.setItem(`${storagePrefix}creatorInfo`, JSON.stringify(creatorInfo))
            //         sessionStorage.setItem(`${storagePrefix}targetInfo`, JSON.stringify(targetInfo))
            //     } else {
            //         sessionStorage.setItem(`${storagePrefix}creatorInfo`, JSON.stringify(targetInfo))
            //         sessionStorage.setItem(`${storagePrefix}targetInfo`, JSON.stringify(creatorInfo))
            //     }
            // }
            this.$router.push({
                path: `/im_new/${item.id}`,
                query: {
                    // creatorInfo: item.creatorInfo ? item.creatorInfo : '',
                    // targetInfo: item.targetInfo ? item.targetInfo : '',
                },
            })
            // sessionStorage.setItem(`${storagePrefix}creatorInfo`, JSON.stringify(creatorInfo))
            // sessionStorage.setItem(`${storagePrefix}targetInfo`, JSON.stringify(targetInfo))
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            if(isToday(time)) {
                return time.format('HH:mm')
            }
            return time.format('YYYY/M/D HH:mm')
        }
    }
})
function isToday(momentDate: moment.Moment) {
    return momentDate.isSame(moment().startOf('day'), 'd')
}
